<template>
  <div id="app" class="holding-page">
    <div class="logo-section">
      <img src="./assets/FlaskCast-Logo.png" alt="FlaskCast Logo" class="logo" />
    </div>

    <div class="content-section">
      <!-- Left Column: Coming Soon -->
      <div class="coming-soon">
        <h2>Coming Soon</h2>
        <p>
          FlaskCast is launching in 2025! Get ready to master Flask, Python's
          lightweight web framework, with step-by-step tutorials, practical
          projects, and much more.
        </p>
        <p>
          Stay tuned for courses covering REST APIs, JWT authentication, 
          Flaskion, and everything you need to become a Flask expert.
        </p>
      </div>

      <!-- Right Column: Welcome to FlaskCast -->
      <div class="welcome-video">
        <h3>Welcome to FlaskCast</h3>
        <p>
          Watch our introduction video to get a sneak peek of what’s coming!
        </p>
        <video controls class="video-player">
          <source src="./assets/intro-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <!-- Footer Section -->
    <footer class="footer">
      <p>&copy; {{ currentYear }} <a href="https://SkyAISoftware.com" target="_blank">SkyAI Software</a>. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
/* Page Styles */
body {
  background-color: #878b96;
}

.holding-page {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #878b96;
  color: #333;
  min-height: 100vh; /* Ensure the container takes up the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes footer to the bottom */
  align-items: center;
}

.footer {
  background-color: #2c2f33;
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  margin-top: 30px;;
  text-align: center;
  font-size: 0.9rem;
  position: relative;
}


.logo-section .logo {
  max-width: 300px;
}

/* Two-column grid for content */
.content-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  align-items: start;
  color: #ffffff;
  width: 100%;
  max-width: 1200px;
}

.coming-soon, .welcome-video {
  padding: 20px;
}

.coming-soon h2, .welcome-video h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.coming-soon p, .welcome-video p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.video-player {
  width: 100%;
  max-width: 100%;
  border: 2px solid #ffffff;
  border-radius: 5px;
  margin-top: 10px;
}


.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-section {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
</style>
